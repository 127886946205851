import React from 'react'
import Results from '../results'

function SearchResults () {
  return (
        <>
         <Results />
        </>
  )
}

export default SearchResults
