import React from 'react'
import FilingData from '../FilingData'

function DataPage () {
  return (
        <>
         <FilingData />
        </>
  )
}

export default DataPage
