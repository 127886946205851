import React from 'react'
import FilingsList from '../CompanyFilingList'

function FilingsPage () {
  return (
        <>
         <FilingsList />
        </>
  )
}

export default FilingsPage
